.rdw-wrapper-iptv {
  height: 25rem !important;
}
.wrapper-class {
  height: 100% !important;
}
.editor-class {
  background: #fff;
  height: 100px;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  height: 80% !important;
}
.rdw-option-wrapper {
  min-width: 2.4rem !important;
  height: 2.4rem !important;
}

.rdw-dropdown-wrapper {
  height: 2.4rem !important;
}
