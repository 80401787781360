.visible,.notvisible {
    position: relative;
}

.visible::after,.notvisible::after {
    pointer-events: none;
    font-size: 1.5em;
    display: block;
    height: calc(100% - 8px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.visible::after {
    content: "VISIBLE";
    border: 4px dashed blue;
    color: blue;
}

.notvisible::after {
    content: "NOT VISIBLE";
    border: 4px dashed red;
    color: red;
}