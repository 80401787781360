$primary: #485Fe0;
$secondary: #1ac884;
$purple: #1d0071;
$red: #ed3838;
$green: #1ac783;
$secondary-light: #e9faf3;
$default: #505972;
$auth-bg: #f5f6fa;
$dropdown-bg: #f9f9fb;
$purple-light: #6e71a8;

$logo-bg: #1d0071;
$nav-hover-bg: #485Fe0;
$nav-open-submenu-bg: #1d0071;
$nav-color: #d8d4e9;
$nav-border-bottom: #d9e0ec;
$profile-border: #cdd3de;
$dropdown-default-shadow: #6e85a8;

$input-color: #67748b;
$input-placeholder-color: #adb6c6;
$input-line-height: 1.188;
$input-border-color: #e9edf4;
$input-bg: #fafbff;
$input-focus-bg: #ffffff;
$input-height: calc(1.5em + 1.38rem + 2px);
$input-focus-border-color: #485Fe0;
$input-focus-box-shadow: none;

$hr-border-color: #e7edf1;

$input-btn-padding-y: 0.75rem;

$graph-text: #949aab;
$pagination-color: #202635;
$pagination-next-bg: #d1f4e6;
$btn-secondary-light-bg: #e8f9f2;
$table-expanded-bg: #ebe9f4;
$danger-light: #f13131;
$tabs-bg: #eaecf4;
$tabs-color: #8691a6;
$tabs-border-color: #d4d8e1;
$input-number-bg: #fcfdfe;
$card-bg-light: #fafafc;

$ui-group-popup-bg: #fbfbfd;
$table-border-distinguish: #dfdcf2;
