.col-24-md-1, .col-24-md-2, .col-24-md-3, .col-24-md-4, .col-24-md-5, .col-24-md-6, .col-24-md-7, .col-24-md-8, .col-24-md-9, .col-24-md-10, .col-24-md-11, .col-24-md-12, .col-24-md-13, .col-24-md-14, .col-24-md-15, .col-24-md-16, .col-24-md-17, .col-24-md-18, .col-24-md-19, .col-24-md-20, .col-24-md-21, .col-24-md-22, .col-24-md-23, .col-24-md-24 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-24-md-1, .col-24-md-2, .col-24-md-3, .col-24-md-4, .col-24-md-5, .col-24-md-6, .col-24-md-7, .col-24-md-8, .col-24-md-9, .col-24-md-10, .col-24-md-11, .col-24-md-12, .col-24-md-13, .col-24-md-14, .col-24-md-15, .col-24-md-16, .col-24-md-17, .col-24-md-18, .col-24-md-19, .col-24-md-20, .col-24-md-21, .col-24-md-22, .col-24-md-23, .col-24-md-24 {
    float: left;
  }
  .col-24-md-24 {
    width: 100%;
  }
  .col-24-md-23 {
    width: 95.83333333%;
  }
  .col-24-md-22 {
    width: 91.66666667%;
  }
  .col-24-md-21 {
    width: 87.5%;
  }
  .col-24-md-20 {
    width: 83.33333333%;
  }
  .col-24-md-19 {
    width: 79.16666667%;
  }
  .col-24-md-18 {
    width: 75%;
  }
  .col-24-md-17 {
    width: 70.83333333%;
  }
  .col-24-md-16 {
    width: 66.66666667%;
  }
  .col-24-md-15 {
    width: 62.5%;
  }
  .col-24-md-14 {
    width: 58.33333333%;
  }
  .col-24-md-13 {
    width: 54.16666667%;
  }
  .col-24-md-12 {
    width: 50%;
  }
  .col-24-md-11 {
    width: 45.83333333%;
  }
  .col-24-md-10 {
    width: 41.66666667%;
  }
  .col-24-md-9 {
    width: 37.5%;
  }
  .col-24-md-8 {
    width: 33.33333333%;
  }
  .col-24-md-7 {
    width: 29.16666667%;
  }
  .col-24-md-6 {
    width: 25%;
  }
  .col-24-md-5 {
    width: 20.83333333%;
  }
  .col-24-md-4 {
    width: 16.66666667%;
  }
  .col-24-md-3 {
    width: 12.5%;
  }
  .col-24-md-2 {
    width: 8.33333333%;
  }
  .col-24-md-1 {
    width: 4.16666667%;
  }
}