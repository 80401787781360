.wrapper {
  .dropdown-default {
    padding: 0.95rem 2rem;
    box-shadow: -2px 8px 16px rgba($dropdown-default-shadow, 0.04);
  }
}

.dropdown-default {
  padding: 1.22rem 2rem;
  background: $auth-bg;
  color: $primary;
  font-size: 1.125rem;
  font-weight: 700;
  position: relative;
  width: 18.75rem;
  display: inline-block;
  .svg-inline--fa {
    position: absolute;
    right: 1.75rem;
    top: 0;
    bottom: 0;
    margin: auto;
    color: $input-placeholder-color;
  }
  &.ant-dropdown-menu {
    padding: 1rem;
    background: $white;
    .ant-dropdown-menu-item {
      font-size: 1rem;
      border-radius: 2rem;
      margin-bottom: 0.3125rem;
      height: 2.5rem;
      line-height: 2.5rem;
      a {
        color: $primary;
        padding-left: 1rem;
        border-radius: 2rem;
        &:hover {
          background: $dropdown-bg;
          color: $secondary;
        }
      }
    }
  }
  &.filter {
    padding: 0.6875rem 1rem;
    width: 7.375rem;
    color: $default;
    font-size: 0.875rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    float: left;
    &.filter-small {
      padding: 0.313rem 1rem;
    }
    .anticon {
      color: $default;
      margin-right: 0.5rem;
    }
  }
}
