.default-tab {
  .ant-tabs-bar {
    border-bottom: none;
    margin: 0 0 1.875rem 0;
  }
  .ant-tabs-content {
    border: transparent 1px solid !important;
    border-left: #e4e4e4 1px solid !important
  
  }
  .ant-table-override {
    border: #e4e4e4 1px solid !important
  }
  .ant-tabs-nav-wrap {
    margin-bottom: 0;
  }
  .ant-tabs-nav {
    .ant-tabs-tab {
      background: rgba($tabs-bg, 0.5);
      margin: 0;
      text-align: center;
      padding: 0.8125rem 1rem;
      font-weight: bold;
      color: $tabs-color;
      &:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
    .ant-tabs-tab-active {
      background: $white;
      border-radius: 0.25rem;
      color: $secondary;
      box-shadow: -2px 8px 16px rgba($dropdown-default-shadow, 0.04);
    }
  }
  .ant-tabs-ink-bar-animated {
    display: none !important;
  }
}
.inline-tab {
  .ant-tabs-bar {
    border-bottom: none;
    margin: 0 0 1.875rem 0;
  }
  .ant-tabs-content {
    border: transparent 1px solid !important;
    border-left:transparent 1px solid !important
  
  }
  .ant-tabs-nav-wrap {
    margin-bottom: 0;
  }
  .ant-tabs-nav {
    .ant-tabs-tab {
      background: rgba($tabs-bg, 0.5);
      margin: 0;
      text-align: center;
      //padding: 0.8125rem 1rem;
      font-weight: bold;
      color: $tabs-color;
      &:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
    .ant-tabs-tab-active {
      background: $white;
      border-radius: 0.25rem;
      color: $secondary;
      box-shadow: -2px 8px 16px rgba($dropdown-default-shadow, 0.04);
    }
  }
  .ant-tabs-ink-bar-animated {
    display: none !important;
  }
}

