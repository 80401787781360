.dashboard {
  background: $auth-bg;
}

@media (min-width: 1200px) {
  .slider {
    .col-xl-2 {
      flex: 0 0 19%;
      max-width: 19%;
    }
    .col-xl-1 {
      flex: 0 0 5%;
      max-width: 5%;
    }
  }
}
