.ant-table-wrapper {
  .ant-table {
    box-shadow: -4px 16px 24px rgba($dropdown-default-shadow, 0.04);
    color: $default;
    .ant-table-body {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .ant-table-content {
      .ant-table-thead {
        > tr {
          > th {
            font-size: 0.75rem;
            padding: 1.375rem 1rem;
            border-bottom-color: $hr-border-color;
            .ant-table-header-column {
              .ant-table-column-sorters {
                &::before {
                  background: $white;
                }
                &:hover {
                  &::before {
                    background: $white;
                  }
                }
              }
            }
          }
        }
      }
      .ant-table-tbody {
        background: $white;
        > tr {
          > td {
            padding: 1.375rem 1rem;
            border-bottom-color: $hr-border-color;
            span {
              display: flex;
              align-items: center;
              img {
                height: 40px;
              }
            }
          }
        }
      }
    }
    .ant-table-selection-column {
      span {
        justify-content: center;
      }
    }
  }
  &.table-img {
    .ant-table {
      .ant-table-content {
        .ant-table-tbody {
          > tr {
            > td {
              padding: 0.75rem 1rem;
            }
          }
        }
      }
    }
  }
}

.ant-pagination-prev,
.ant-pagination-next {
  .ant-pagination-item-link {
    background: none;
    border: none;
  }
}
.ant-pagination-item {
  background: none;
  border: none;
  line-height: 1.688rem;
  min-width: 1.563rem;
  height: 1.563rem;
  &.ant-pagination-item-active,
  &:hover {
    background: $secondary;
    color: $white;
    a {
      color: $white;
    }
  }
}

.pagination-blue {
  .ant-pagination-item {
    &.ant-pagination-item-active,
    &:hover {
      background: $input-focus-border-color;
      color: $white;
      a {
        color: $white;
      }
    }
  }
}

.ant-table-pagination {
  &.ant-pagination {
    margin-top: 2.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    .ant-pagination-total-text {
      order: 2;
      margin-left: auto;
    }
  }
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  line-height: 1.313rem;
  min-width: 1.563rem;
  height: 1.563rem;
  font-size: 0.875rem;
}

.ant-pagination {
  color: $pagination-color;
}

.ant-table-expanded-row {
  > td {
    background: $table-expanded-bg;
    &:first-child {
      border: 1px solid $table-border-distinguish;
      border-left: 4px solid $input-focus-border-color;
    }
  }
  .ant-table-pagination {
    &.ant-pagination {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
}



.ui-groups {
  .ant-table-expanded-row {
    > td {
      padding-left: 1.5rem !important;
    }
  }
  .dragicon {
    width: 3.75rem;
    text-align: right;
  }
  .expandicon {
    width: 3.75rem;
    vertical-align: top;
  }
  .ant-table-thead > tr,
  .ant-table-tbody > tr {
    transition: none;
  }
}

.shadow-none {
  &.ant-table-wrapper {
    .ant-table {
      box-shadow: none;
    }
  }
}

@media (max-width: 767px) {
  .ant-table-pagination {
    &.ant-pagination {
      float: none;
      display: flex;
      justify-content: center;
    }
  }
  .ui-groups {
    .ant-table-expanded-row {
      > td {
        padding-left: 0 !important;
      }
    }
  }
}
