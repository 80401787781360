.card {
  border: none;
  box-shadow: -4px 16px 24px rgba($purple-light, 0.04);
  &.card-shadow-hover {
    transition: all 0.5s ease 0s;
    &:hover {
      .border {
        border-color: transparent !important;
      }
      box-shadow: 0px 8px 32px rgba(110, 133, 168, 0.24) !important;
    }
  }
}

.card-padding {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.card-padding {
  padding: 2.75rem 3.25rem !important;
}

.card-border {
  border-color: $nav-border-bottom;
}

.card-bg-light {
  background: $card-bg-light;
}

.card-col-width {
  .col-md-7 {
    flex: 0 0 57.33333%;
    max-width: 57.33333%;
  }
  .offset-md-1 {
    margin-left: 9.33333%;
  }
}

.card-ch-padding {
  padding: 3.75rem 6.25rem;
}

@media (max-width: 767px) {
  .card-padding {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

.card-291 {
  height: 100%;
  background: none;
}

.card {
  border: none;
  box-shadow: -4px 16px 24px rgba($purple-light, 0.04);
  &.card-hover-color {
    transition: all 0.5s ease 0s;
    &:hover {
      transition: all 0.5s ease 0s;
      background-color: #fff;
    }
  }
}

// graph-card-padding
.graph-card-padding {
  padding: 2.1875rem;
}
