@import url('https://fonts.googleapis.com/css2?family=Abel&family=Lato&family=Montserrat&family=Open+Sans&family=Oswald&family=Roboto+Condensed:ital@1&display=swap');

body {
  margin: 0;
  font-family: Cabin !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Cabin !important;
}
