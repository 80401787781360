.auth {
  background-color: $auth-bg;
  height: 100vh;

  .auth-left {
    background: url('../images/bgblue.png');
    background-position: center;
    box-shadow: -4px 16px 24px rgba($purple-light, 0.04);

  }

  &.register-page {
    .auth-right {
      padding: 75px;
    }
  }
  .auth-right {
    padding: 75px;
    box-shadow: -4px 16px 24px rgba($purple-light, 0.04);
  }
  .btn {
    width: 185px;
  }
  &.forgot-pass {
    .auth-right {
      padding: 75px;
    }
    p {
      line-height: 22px;
    }
  }
  .phone_prefix {
    .ant-select-selection--single {
      height: 100%;
      padding-top: 7px;
    }
  }
}

@media (max-width: 1199px) {
  .auth {
    &.forgot-pass {
      .auth-right {
        padding: 75px;
      }
    }
  }
}

@media (max-width: 991px) {
  .auth {
    &.register-page {
      .auth-right {
        padding: 75px;
      }
    }
    .auth-right {
      padding: 75px;
    }
    &.forgot-pass {
      .auth-right {
        padding: 75px;
      }
    }
  }
}

@media (max-width: 767px) {
  .auth {
    background-color: $white;
    .auth-left {
      background-position: top;
    }
    .auth-right {
      box-shadow: none;
    }
    &.forgot-pass {
      .auth-right {
        padding: 75px;
      }
    }
  }
}
