.iptv-color-picker {
  .color-visual-box {
    padding: 5px 0.938rem 5px 1.25rem;
    background: rgb(255, 255, 255);
    cursor: pointer;
    .inner-box {
      width: 100%;
      height: 1.75rem;
      border-radius: 0.125rem;
      margin-right: 0.938rem;
    }
    .inner-icon {
      width: 1.25rem;
      height: 1.25rem;
      position: relative;
    }
  }
  .picker-section {
    position: absolute;
    z-index: 2;
    left: 1.875rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;
  }
  .picker-box {
    padding: 0.313rem;
    width: 14.063rem;
    background: rgb(255, 255, 255);
    border-radius: unset !important;
    box-shadow: none !important;
    box-sizing: initial;
  }
}
