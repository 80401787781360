.ant-checkbox-wrapper {
  &.deselect-all {
    .ant-checkbox-inner {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100%;
      &:after {
        transform: rotate(45deg) scale(1.8) translate(-50%, -50%);
        margin-top: 3px;
        margin-left: 6px;
      }
    }
    .ant-checkbox-checked {
      &::after {
        border: none;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border: none;
      }
    }
  }
}

.ant-checkbox-inner {
  border-color: $profile-border;
}

.search-default {
  width: auto;
  input {
    width: 8.25rem;
    box-shadow: -2px 8px 16px rgba($purple-light, 0.04);
    border: none;
    height: 3rem;
    padding: 0.25rem 1.938rem;
    &::placeholder {
      color: $default;
    }
  }
  &.ant-input-affix-wrapper {
    .ant-input-prefix {
      right: inherit;
      font-size: 1rem;
      left: 1.25rem;
      svg {
        fill: $input-placeholder-color;
      }
    }
    .ant-input:not(:first-child) {
      padding-left: 2.813rem;
      padding-right: 1.938rem;
    }
  }
}

.search-with-button {
  width: auto;
  input {
    box-shadow: -2px 8px 16px rgba($purple-light, 0.04);
    border: none;
    height: 3rem;
    padding: 0.25rem 1.938rem;
    &::placeholder {
      color: $default;
    }
  }
  .ant-input-search-button {
    height: 3rem;
    .anticon {
      font-size: 1rem;
    }
  }
}

.default-select {
  .ant-select-selection {
    border: 1px solid $input-border-color;
    border-radius: 0.25rem;
    padding: 0.625rem 0.3125rem;
    min-height: 3rem;
    color: $input-placeholder-color;
  }
  &.bg-gray {
    .ant-select-selection {
      background: $input-number-bg;
    }
  }
  &.small-select {
    .ant-select-selection {
      height: 2rem;
    }
    .ant-select-selection__rendered {
      line-height: 0.75rem;
    }
  }
}

.default-input-group {
  input {
    padding: 0.75rem 0.75rem;
    height: 48px;
    border: 1px solid $input-border-color;
    border-radius: 0.25rem;
  }
  .ant-input-group-addon {
    background-color: $input-border-color;
    border: 1px solid $input-border-color;
    padding: 0 21px;
    color: $input-placeholder-color;
    font-size: 20px;
  }
}

.ant-form-extra {
  font-size: 0.75rem;
  color: $tabs-color;
  line-height: 1rem;
}

.default-input-number {
  &.ant-input-number {
    background: $input-number-bg;
    .ant-input-number-handler-wrap {
      opacity: 1;
      border: none;
      width: 2.813rem;
      background: $input-number-bg;
      .ant-input-number-handler-up-inner,
      .ant-input-number-handler-down-inner {
        font-size: 1rem;
        left: 0;
        right: 0;
        margin: -0.3125rem auto 0;
      }
      .ant-input-number-handler-down {
        border: none;
        height: 50% !important;
        .ant-input-number-handler-down-inner {
          margin: -0.625rem auto 0;
        }
      }
      .ant-input-number-handler-up {
        height: 50% !important;
      }
    }
  }
}

.small-input {
  width: 4.875rem;
  padding: 0.25rem 1.125rem;
}

.ant-form-item {
  padding-bottom: 1.375rem;
  margin-bottom: 0.5rem;
  position: relative;
  .ant-form-explain {
    position: absolute;
  }
}

.col-form-label {
  padding-top: 1rem;
}

.channel-form {
  .form-control {
    height: calc(1.5em + 1.38rem + 5px);
  }
}

.default-upload {
  .ant-upload {
    &.ant-upload-drag {
      background: $card-bg-light;
      border: 1px dashed $input-border-color;
    }
  }
}

@media (min-width: 1200px) {
  .search-default {
    input {
      &:focus {
        width: 15rem;
      }
    }
  }
}
.form-control > .ant-select-selector {
  border: none !important;
  background: inherit !important;
  margin-top: -5px;
  box-shadow: none !important;
}
