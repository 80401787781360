@import 'assets/sass/custom-variables';
@import '~bootstrap/scss/bootstrap';
@import 'assets/sass/common.scss';
@import 'assets/sass/form-control.scss';
@import 'assets/sass/card.scss';
@import 'assets/sass/table.scss';
@import 'assets/sass/tab.scss';
@import 'assets/sass/dropdown.scss';
@import 'assets/sass/auth.scss';
@import 'assets/sass/layout.scss';
@import 'assets/sass/dashboard.scss';
@import 'assets/sass/suspense-loader.scss';
@import 'assets/sass/react-draft-wysiwyg.scss';
@import 'assets/sass/iptv-color-picker.scss';
@import 'assets/sass/grid24columns.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Cabin');

body {
  font-family: Cabin !important;
}

html {
  font-family: Cabin !important;
}

svg {
  vertical-align: unset !important;
}

// .btn-default{
//   padding: 0.9rem 1rem !important;
// }

.chrome-picker {
  box-shadow: 'none' !important;
}
.rounded-pill {
  border-radius: 5px !important;
  font-family: Lato !important;
}
.ant-select {
  font-size: 12px !important;
  // margin-top: 5px !important;
  //   margin-bottom: 5px !important;
  border-radius: 5px !important;
  font-family: Lato !important;
}
.ant-table-container {
  width: 100% !important;
}
.table-layout {
  width: 100% !important;
}

table {
  width: 100% !important;
}
.ant-table {
  width: 100% !important;
}
.ant-table-row {
  height: 80px !important;
}
.ant-table-thead {
  height: 80px !important;
}
.btn-secondary:hover {
  color: #ffffff !important;
  border-color: #17af75 !important;
  border-width: 2px;
  background-color: #19cb84 !important;
}
.ant-table-cell {
  border-top: 1px solid #e2e2e2 !important;
  border-bottom: 1px solid #e2e2e2 !important;
  border-right: 1px solid #e2e2e2 !important;
  border-left: 1px solid #e2e2e2 !important;
  padding-left: 20px;
  padding-right: 20px;
  color: #777777;
  background: #ffffff;
}

.ruleGroup {
  margin-left: 5px !important;
  border-color: #c4c4c4 !important;
  background: rgba(189, 189, 189, 0.2) !important;
}
.horizontal-scrollable {
  overflow-x: auto;
  white-space: nowrap;
}
.horizontal-scrollable > .row > .col-xs-4 {
  display: inline-block;
  float: none;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 100px !important;
}
.ant-select-selector {
  border-radius: 5px;
}

.ant-select-selection-item-disabled {
  display: none !important;
}
.ant-tabs-content-holder {
  background-color: white !important;
}
.ant-select-item-option-disabled {
  display: none !important;
}

.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

button:focus {
  border: none !important;
  outline: none !important;
}

.apexcharts-tooltip {
  border-radius: 5px;
  box-shadow: 2px 2px 6px -4px #999;
  cursor: default;
  font-size: 14px;
  left: 62px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: 0.15s ease all;
  font-family: Cabin m !important;
}

.ant-input-number-input {
  padding-left: 0px;
}

// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: #ddeefd;
//   border-top-right-radius: 100px;
//   border-bottom-right-radius: 100px;
// }

// .ant-menu-vertical .ant-menu-item::after,
// .ant-menu-vertical-left .ant-menu-item::after,
// .ant-menu-vertical-right .ant-menu-item::after,
// .ant-menu-inline .ant-menu-item::after {
//   border-right: 3px solid transparent !important;
// }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-top: 9px !important;
  padding-bottom: 4px !important;
  height: 50px !important;
  //padding-left: 20px !important;
  color: #505972 !important;
  border: 1px solid #e9edf4;
}

.ant-modal {
  padding-bottom: 0px !important;
}

.form-control {
  height: 100% !important;
  min-height: 50px;
  padding-top: 12px !important;
  min-width: 70px !important;
}

.ant-form-item-extra {
  padding: 10px !important;
  font-size: 12px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #485fe0;
  font-family: Lato;
}

.wrapper .btn-default {
  padding: 0.7rem 2rem;
  min-width: 13rem !important;
  margin: 5px;
}

.ant-picker-clear {
  display: none !important;
}

.ant-select-item {
  position: relative;
  padding: 12px 12px !important;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.8s ease;
  padding-left: 15px !important;
}

.ant-switch {
  margin-top: 15px;
}

.ant-picker {
  min-height: 50px;
  min-width: 200px;
  margin-bottom: 30px;
}

.ant-select-selector {
  min-height: 50px;
}
.ant-menu-submenu-open {
  background-color: #002140;
}
.ant-menu {
  padding-left: 10px;
  padding-right: 10px;
}
.ant-menu-title-content.a {
  font-size: 9;
}
.ant-menu-item-selected {
  background-color: transparent !important;
}
.ant-menu-item-selected {
  background-color: #485fe0 !important;
  border-radius: 5px !important;
}
.ant-tooltip-inner {
  font-size: 12px !important;
}

.card-ch-padding {
  padding: 0.75rem 3.25rem !important;
}

.chart {
  font-family: Cabin !important;
}

.wrapper {
  padding: 2rem 2rem 2rem !important;
}

.ant-tag {
  border-radius: 50px;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  font-style: bold !important;
  margin-top: 5px;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}

.form-control-extra {
  width: 100%;
  height: calc(1.5em + 1.38rem + 2px);
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.188;
  color: #67748b;
  background-color: #fafbff;
  background-clip: padding-box;
  border: 1px solid #e9edf4;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ant-table-expanded-row > td:first-child {
  border: 1px solid #dfdcf2 !important;
  border-left: 4px solid #1cc883 !important;
}

.ant-tooltip-inner {
  width: '100%' !important;
}

.drag-handle {
  cursor: move !important;
}

.global-drag-line {
  border-color: #1ac884 !important;
}

.ant-form-item .ant-upload.ant-upload-drag {
  background-color: #fff !important;
}

.ant-table {
  color: #4f4f4f !important;
}

.card-bg-light {
  border: 1px solid #ebebeb !important;
  background-color: 'fbfbfb' !important;
}

.card-bg-white {
  border: 1px solid #ebebeb !important;
  background-color: #fff !important;
}

.ant-select {
  background-color: #fff !important;
  border-radius: 10px !important;
}

.action-icon {
  color: #999 !important;
}

.ant-table-wrapper {
  // border: 1px solid #dadada !important;
  border-radius: 2px !important;
}

.ant-switch-checked {
  background-color: #3dd495 !important;
}

#scaled-frame {
  zoom: 0.5;
  -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #scaled-frame {
    zoom: 1;
  }
}

element.style {
  color: #999;
}

.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
th {
  background-color: #f7f7f7 !important;
}

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
  font-family: Cabin !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

user agent stylesheet div {
  display: block;
}

.default-tab .ant-tabs-nav .ant-tabs-tab {
  color: #999;
}

.highlight-tab .ant-tabs-nav .ant-tabs-tab {
  color: #999;
}

.remove-hor-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-tabs-tab {
  font-size: 11px !important;
  background-color: #f2f2f2 !important;
  border-right: #dfdfdf 1px solid !important;
  border-bottom: #dfdfdf 1px solid !important;
  font-size: 12px;
}
.ant-modal .ant-modal-close-x {
  justify-content: normal !important;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 0px !important;
}

.ant-tabs-tab-active {
  background-color: #fff !important;
  color: #4f4f4f !important;
}

.default-tab .ant-tabs-content {
  border: none !important;
}

.ant-tabs-tabpane {
  background: #fff !important;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}

.public-DraftEditor-content {
  background-color: #fafafa !important;
  text-shadow: 1px 1px #c1c1c1 !important;
}

.main-tooltip-value {
  .info-date {
    font-size: 12px;
  }

  .info-value {
    font-size: 16px;
    font-weight: 700;
  }
}

// Ui Channel Popup
li.channel-grid-list {
  display: inline-block;
  width: 19%;
  text-align: center;
}
.btn-btn-primary {
  color: #fff;
  background-color: #818181 !important;
  border-color: #444444 !important;
}

.channelSearch {
  button.ant-btn.ant-input-search-button.ant-btn-primary.ant-btn-lg {
    background: #e8f9f2;
    border: none;
    border-radius: 30px;
    padding: 0 40px;
    color: #1ac884;
    z-index: 999;
    margin-left: -25px;
  }

  .ant-input {
    border-radius: 24px;
    background: #fcfdfe;
    border: 1px solid #e9edf4;
    height: 48px;
    padding-left: 30px;
  }

  .ant-btn {
    height: 48px;
    width: 161px;

    &[ant-click-animating-without-extra-node]:after {
      animation: 0s !important;
    }
  }
}

// .ant-layout .ant-layout-sider-children .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
//   height: 100% !important;
// }
// .ant-layout .ant-layout-sider-children .ant-menu {
//   background: #6c757d !important;
//   color: #d8d4e9;
//   font-size: 1rem;
//   border-right: none;
// }
// .ant-layout .ant-layout-sider-children .ant-menu .ant-menu-submenu.ant-menu-submenu-open {
//   background: #6c757d;
// }

// .ant-layout .ant-layout-sider-children .ant-menu {
//   font-size: 13px !important;
// }
// .ant-layout .ant-layout-sider-children .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
//   height: 50px !important;
//   display: flex;
//   align-items: center;
//   border-top-left-radius: 10px !important;
//   border-bottom-right-radius: 10px !important;
//   border-top-right-radius: 10px !important;
// }
// .ant-layout .ant-layout-sider-children .ant-menu .ant-menu-submenu {
//   margin: 8px !important;
// }
// .ant-layout .ant-layout-sider-children .ant-menu > li {
//   padding-left: 0px !important;
// }
.select-all {
  &.select-all-small {
    .ant-checkbox-inner {
      width: 26px;
      height: 26px;
      border-width: 2px;

      &:after {
        transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
        margin-top: 0px;
        margin-left: 2px;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: $white;
      }
    }
  }

  .ant-checkbox-inner {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;

    &:after {
      transform: rotate(45deg) scale(1.8) translate(-50%, -50%);
      margin-top: 3px;
      margin-left: 6px;
      border-color: $input-placeholder-color;
      opacity: 1;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      &:after {
        border-color: $white;
      }
    }

    &::after {
      border-color: transparent;
    }
  }
}

.max-width-600 {
  max-width: 600px;
}

.ant-input-group-addon {
  background-color: transparent;
}

.ant-btn-primary {
  border-color: #3dd495;
  background: #3dd495;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #3dd495;
  background: #3dd495;
}

.add-channelGroup {
  padding: 60px 100px 30px 100px !important;
}

.add-channelBtn {
  padding: 50px 130px 70px 0px !important;
}

.graph-label-center {
  color: #505972;
  text-align: center;
  width: 10em;
}
.ant-select-item-option-content {
  white-space: unset;
}
.right-14 {
  right: 14px;
}

.ui-group-popup-bg {
  background: $ui-group-popup-bg;
}

.focus-none {
  .ant-input {
    &:focus {
      box-shadow: none;
    }
  }
}

@media (max-width: 767px) {
  li.channel-grid-list {
    width: 33%;
  }
}

.add-records-search {
  button.ant-btn.ant-input-search-button.ant-btn-primary.ant-btn-lg {
    background: #ffffff;
    font-weight: bold;
    border: none;
    border-radius: 30px;
    padding: 0 40px;
    color: #1ac884;
    z-index: 1;
    margin-left: -25px;
  }

  .ant-input {
    border-radius: 24px;
    background: #e9edf4;
    border: 1px solid #e9edf4;
    height: 48px;
    padding-left: 30px;
  }

  .ant-btn {
    height: 48px;
    width: 161px;

    &[ant-click-animating-without-extra-node]:after {
      animation: 0s !important;
    }
  }
}

.ant-modal-close {
  outline: none !important;
}

.ant-modal-close-x {
  display: block;
  font-size: 23px;
  line-height: 56px;
  text-transform: none;
  text-rendering: auto;
  padding-right: 30px;
}

.btn-hover:hover {
  border: 1px solid $secondary;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell)::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
}

.react-split {
  flex: 1;
  height: 100%;
  position: relative;
  width: 100%;
}

.react-split__pane {
  height: 100%;
  position: absolute;
  white-space: normal;
  width: 100%;
  overflow: hidden;
}

.react-split__sash {
  height: 100%;
  position: absolute;
  top: 0;
  transition: background-color 0.1s;
  width: 100%;
  z-index: 2;
}

.react-split__sash--disabled {
  pointer-events: none;
}

.react-split__sash--vertical {
  cursor: col-resize;
}

.react-split__sash--horizontal {
  cursor: row-resize;
}

.react-split__sash-content {
  width: 100%;
  height: 100%;
}

.react-split__sash-content--active {
  background-color: #175ede;
}

.react-split--dragging.react-split--vertical {
  cursor: col-resize;
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.react-split--dragging.react-split--horizontal {
  cursor: row-resize;
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1 > textarea,
.ant-typography h1 {
  font-size: 20px !important;
}

body.react-split--disabled {
  user-select: none;
}

.split-sash-content {
  width: 100%;
  height: 100%;
}

.split-sash-content.split-sash-content-vscode.split-sash-content-active {
  background-color: #175ede;
}

.react-split--dragging.react-split--horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.react-split--dragging.react-split--vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.crossed {
  background: linear-gradient(to top left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 2px), rgba(196, 196, 196, 1) 50%, rgba(0, 0, 0, 0) calc(50% + 2px), rgba(0, 0, 0, 0) 100%), linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 2px), rgba(196, 196, 196, 1) 50%, rgba(0, 0, 0, 0) calc(50% + 2px), rgba(0, 0, 0, 0) 100%);
}

.mware-item-with-icon-label label {
  height: 40px;
}

.ant-form-item-label label::after {
  display: none !important;
}

.ant-form-item-label label::before {
  display: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1ac884;
}

.ant-input:hover {
  border-color: #3dd495 !important;
  border-width: 2px !important;
}

.form-control:focus {
  border-color: #3dd495 !important;
  border-width: 2px !important;
}
.ant-select:hover {
  border-color: #3dd495 !important;
  border-width: 2px !important;
}
.ant-select:focus {
  border-color: #3dd495 !important;
  border-width: 2px !important;
}
.ant-select-selector:hover {
  border-color: #3dd495 !important;
  border-width: 2px !important;
  border-radius: 5px;
}
.ant-select-selector:focus {
  border-color: #3dd495 !important;
  border-width: 2px !important;
  border-radius: 5px;
}
.ant-select-selector:active {
  border-color: #3dd495 !important;
  border-width: 2px !important;
  border-radius: 5px;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.ant-select-focused:hover {
  border-color: #3dd495 !important;
  border-width: 2px !important;
  border-radius: 5px;
}
.ant-select-item {
  background-color: #ffffff !important;
  border-radius: 5px;
  //margin: 5px;
}
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;

  border-color: #3dd495 !important;
  border-width: 2px !important;
  border-radius: 5px;
}

.ant-select-dropdown {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-select-item-option-active {
  background-color: #f0f0f0 !important;
  // margin-left: 10px;
  //margin: 5px;
  border-radius: 5px;
}

// .ant-select-item-option {
//   margin: 10px
// }

.ant-switch-checked {
  background-color: #3dd495;
}
.col-form-label {
  padding-top: calc(0.75rem + 5px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: normal !important;
  font-family: Lato !important;
}
.ant-form-item {
  font-family: Lato !important;
}

.collapsible-content-edonec {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  border-bottom: 1px solid #dee2e6 !important;
}
.title-text-edonec {
  display: block;
  font-size: 1em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.collapsible-header-edonec {
}
.collapsible-content-padding-edonec {
}
.rotate-center-edonec {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.rotate-center-edonec.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-center-edonec.up {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.collapsible-icon-button-edonec {
  cursor: pointer;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 100%;
}

.collapsible-card-edonec {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.collapsible-card-edonec:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.btn-info {
  background-color: #485fe0 !important;
  border-color: #485fe0 !important;
  color: #fff;
}
