.areas-spinner {
  height: calc(100vh - 65px);
}
.header-dropdown {
  &.ant-dropdown-menu {
    padding: 2.5rem 3rem;
    margin-top: 0.75rem;
    box-shadow: 0px 16px 40px rgba($primary, 0.08);
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: -5px;
      z-index: 23;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $white;
      right: 69px;
    }
    &.user-dropdown {
      margin-top: -0.25rem;
    }
    .media {
      min-width: 300px;
      img {
        width: 60px;
      }
    }
    .ant-menu {
      .ant-menu-item {
        a {
          color: $default;
          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
}

.ant-menu-horizontal {
  line-height: 2.5rem !important;
  .ant-menu-item,
  .ant-menu-submenu {
    border-radius: 24px;
    border-bottom: none;
    &:hover,
    &.ant-menu-submenu-open {
      border-bottom: none;
      background: $secondary-light;
      color: $default;
    }
    &:hover,
    &.ant-menu-submenu-active {
      border-bottom: none;
      background: $secondary-light;
      color: $default;
    }
    a,
    .ant-menu-submenu-title {
      color: $default;
      &:hover {
        color: $primary;
      }
    }
  }
}

.ant-menu-submenu-popup {
  padding-top: 0.3125rem;
  background: transparent;
  &.deployment-menu {
    .ant-menu-item-group {
      display: inline-block;
      padding-right: 5rem;
      &:last-child {
        padding-right: 1.125rem;
      }
    }
    .ant-menu {
      .ant-menu-item-group-title {
        margin-left: 1.125rem;
        margin-bottom: 1rem;
        display: inline-block;
      }
    }
  }

  .ant-menu {
    // padding: 2rem 3rem;
    // box-shadow: 0px 16px 40px rgba($primary, 0.08);
    // .ant-menu-item-group-title {
    //   font-size: 1rem;
    //   color: $primary;
    //   border-bottom: 1px solid $nav-border-bottom;
    //   padding-left: 0;
    //   padding-right: 0;
    //   font-weight: 700;
    //   margin-bottom: 2rem;
    // }
    // .ant-menu-item {
    //   border-radius: 24px;
    //   border-bottom: none;
    //   padding-left: 1.125rem;
    //   margin: 0;
    //   &:hover {
    //     border-bottom: none;
    //     background: $dropdown-bg;
    //     color: $default;
    //   }
    //   a {
    //     font-size: 1rem;
    //     color: $primary;
    //     &:hover {
    //       color: $secondary;
    //     }
    //   }
    // }
  }
}

// .ant-menu-submenu-selected {
//   color: $white;
//   // .hover-icon {
//   //   &.d-none {
//   //     display: inline-block !important;
//   //   }
//   // }
//   .hideon-hover {
//     display: none;
//   }
// }

// .ant-menu-inline-collapsed {
//   > .ant-menu-submenu > .ant-menu-submenu-title {
//     padding: 0 27px !important;
//   }
//   > .ant-menu-item {
//     padding: 0 27px !important;
//     span {
//       opacity: 0;
//     }
//   }
// }

.ant-layout {
  min-height: 100vh;
  .ant-layout-header {
    background: $white;
    line-height: inherit;
    box-shadow: inset 0px -1px $input-border-color;
    nav {
      margin-bottom: 1px;
    }
    .nav-button {
      background: $purple;
    }
    .user-menu {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        height: 25px;
        border-left: 1px solid $profile-border;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
      }
    }
    // .navbar {
    //     .menu-icon {
    //         background: $logo-bg;
    //     }
    //     .nav-item {
    //         position: relative;
    //         .nav-link {
    //             color: $default;
    //             border-radius: 24px;
    //             &:hover {
    //                 background: $secondary-light;
    //                 .anticon {
    //                     color: $secondary;
    //                 }
    //             }
    //         }
    //         &:last-child {
    //             .nav-link {
    //                 &:hover {
    //                     background: none;
    //                 }
    //                 img {
    //                     width: 48px;
    //                 }
    //             }
    //             &::before {
    //                 position: absolute;
    //                 content: "";
    //                 height: 25px;
    //                 border-left: 1px solid $profile-border;
    //                 top: 0;
    //                 bottom: 0;
    //                 margin: auto;
    //             }
    //         }
    //     }
    // }
  }
  // .ant-layout-sider {
  //   background: $purple;
  //   &.ant-layout-sider-collapsed {
  //     .logo {
  //       .expand {
  //         display: none;
  //       }
  //       .collapsed {
  //         display: inline-block;
  //       }
  //     }
  //   }
  // }
  .ant-layout-sider-children {
    .logo {
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $logo-bg;
      box-shadow: -4px 16px 24px rgba($purple-light, 0.04);
      .expand {
        display: inline-block;
      }
      .collapsed {
        display: none;
      }
    }
    // .ant-menu {
    //   background: $purple;
    //   color: $nav-color;
    //   font-size: 1rem;
    //   border-right: none;
    //   &.ant-menu-inline {
    //     width: 88%;
    //   }
    //   > li {
    //     border-top-right-radius: 2rem;
    //     border-bottom-right-radius: 2rem;
    //     &::after {
    //       display: none;
    //     }
    //     i {
    //       margin-right: 0.875rem;
    //     }
    //     &.ant-menu-item {
    //       &:hover {
    //         color: $white;
    //         border-top-right-radius: 2rem;
    //         border-bottom-right-radius: 2rem;
    //         background: $nav-hover-bg;
    //         a {
    //           color: $white;
    //         }
    //         .hover-icon {
    //           &.d-none {
    //             display: inline-block !important;
    //           }
    //         }
    //         .hideon-hover {
    //           display: none;
    //         }
    //       }
    //       &:active {
    //         background: $nav-hover-bg;
    //       }
    //       a {
    //         color: $nav-color;
    //       }
    //     }
    //   }
    //   // .ant-menu-submenu {
    //   //   margin-bottom: 8px;
    //   //   &:hover {
    //   //     .ant-menu-submenu-title {
    //   //       color: $white;
    //   //       border-top-right-radius: 2rem;
    //   //       border-bottom-right-radius: 2rem;
    //   //       background: $nav-hover-bg;
    //   //     }
    //   //     .hover-icon {
    //   //       &.d-none {
    //   //         display: inline-block !important;
    //   //       }
    //   //     }
    //   //     .hideon-hover {
    //   //       display: none;
    //   //     }
    //   //   }
    //   //   .ant-menu-submenu-title {
    //   //     height: 60px;
    //   //     display: flex;
    //   //     align-items: center;
    //   //     border-top-right-radius: 2rem;
    //   //     border-bottom-right-radius: 2rem;
    //   //     .ant-menu-submenu-arrow {
    //   //       &::before,
    //   //       &::after {
    //   //         background: linear-gradient(to right, $nav-color, $nav-color);
    //   //       }
    //   //     }
    //   //     &:hover {
    //   //       color: $white;
    //   //       .ant-menu-submenu-arrow {
    //   //         &::before,
    //   //         &::after {
    //   //           background: linear-gradient(to right, $white, $white);
    //   //         }
    //   //       }
    //   //     }
    //   //     &:active {
    //   //       background: $nav-hover-bg;
    //   //     }
    //   //     span {
    //   //       display: flex;
    //   //       align-items: center;
    //   //     }
    //   //   }
    //   //   &.ant-menu-submenu-open {
    //   //     background: $nav-open-submenu-bg;
    //   //     .ant-menu-submenu-title {
    //   //       color: $white;
    //   //       background: $nav-hover-bg;
    //   //       .ant-menu-submenu-arrow {
    //   //         &::before,
    //   //         &::after {
    //   //           background: linear-gradient(to right, $white, $white);
    //   //         }
    //   //       }
    //   //       .hover-icon {
    //   //         &.d-none {
    //   //           display: inline-block !important;
    //   //         }
    //   //       }
    //   //       .hideon-hover {
    //   //         display: none;
    //   //       }
    //   //     }
    //   //     .ant-menu-item {
    //   //       a {
    //   //         color: $nav-color;
    //   //       }
    //   //     }
    //   //     .ant-menu-item-selected,
    //   //     .ant-menu-item:hover {
    //   //       background: inherit;
    //   //       box-shadow: none;
    //   //       color: $white;
    //   //       position: relative;
    //   //       a {
    //   //         color: $white;
    //   //       }
    //   //       &::before {
    //   //         position: absolute;
    //   //         content: "";
    //   //         height: 2rem;
    //   //         border-left: 2px solid $white;
    //   //         left: 0;
    //   //         top: 0;
    //   //         bottom: 0;
    //   //         margin: auto;
    //   //       }
    //   //     }
    //   //   }
    //   // }
    //   // .ant-menu-item-selected {
    //   //   color: $white;
    //   //   background: $nav-hover-bg;
    //   //   box-shadow: -4px 16px 24px rgba($purple-light, 0.04);
    //   //   a {
    //   //     color: $white !important;
    //   //   }
    //   //   .hover-icon {
    //   //     &.d-none {
    //   //       display: inline-block !important;
    //   //     }
    //   //   }
    //   //   .hideon-hover {
    //   //     display: none;
    //   //   }
    //   // }
    //   // &.ant-menu-inline-collapsed {
    //   //   li {
    //   //     border-radius: inherit;
    //   //     &.ant-menu-item {
    //   //       &:hover {
    //   //         border-radius: inherit;
    //   //       }
    //   //     }
    //   //     .menu-icon + span {
    //   //       display: inline-block;
    //   //       max-width: 0;
    //   //       opacity: 0;
    //   //     }
    //   //   }
    //   //   .ant-menu-submenu {
    //   //     &:hover {
    //   //       border-radius: inherit;
    //   //     }
    //   //     .ant-menu-submenu-title {
    //   //       border-radius: inherit;
    //   //     }
    //   //     &.ant-menu-submenu-open {
    //   //       background: inherit;
    //   //     }
    //   //   }
    //   // }
    //   // .ant-menu-sub {
    //   //   background: $nav-open-submenu-bg;
    //   // }
    // }
  }
}

// @media (min-width: 768px) {
//   .setting-menu {
//     left: 31.5% !important;
//   }
//   .publishing-menu {
//     left: 38.2% !important;
//   }
// }

// @media (min-width: 991px) {
//   .setting-menu {
//     left: 36.5% !important;
//   }
//   .publishing-menu {
//     left: 27.2% !important;
//   }
//   .deployment-menu {
//     left: 2% !important;
//   }
// }

// @media (min-width: 1200px) {
//   .deployment-menu {
//     left: 20% !important;
//   }
//   .setting-menu {
//     left: 56% !important;
//   }
//   .publishing-menu {
//     left: 35% !important;
//   }
// }

// @media (min-width: 1600px) {
//   .deployment-menu {
//     left: 23.8% !important;
//   }
//   .setting-menu {
//     left: 58.2% !important;
//   }
//   .publishing-menu {
//     left: 45.2% !important;
//   }
// }

// @media (min-width: 1920px) {
//   .deployment-menu {
//     left: 36.5% !important;
//   }
//   .setting-menu {
//     left: 65.2% !important;
//   }
//   .publishing-menu {
//     left: 69.4% !important;
//   }
// }

@media (max-width: 1199px) {
  .header-dropdown {
    &.ant-dropdown-menu {
      &.deployment-menu {
        min-width: 991px;
      }
    }
  }
  .ant-menu-submenu-title {
    padding: 0 0.875rem;
  }
  .ant-menu-submenu-popup {
    &.deployment-menu {
      .ant-menu-item-group {
        padding-right: 3rem;
      }
    }
  }
  .ant-layout-sider {
    margin-left: -315px;
    z-index: 99;
    position: fixed;
  }
  body {
    &.mobile-sider {
      .ant-layout-sider {
        margin-left: 0;
        height: 100%;
        transition: all 0.5s ease 0s;
        overflow-x: auto;
      }
      .ant-layout {
        position: relative;
        &::before {
          position: absolute;
          top: 0;
          z-index: 1;
          background: rgba($primary, 0.1);
          content: "";
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transition: all 0.5s ease 0s;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .header-dropdown {
    &.ant-dropdown-menu {
      &::before {
        right: 47px;
      }
      &.deployment-menu {
       // min-width: auto;
      }
    }
  }
  .ant-menu-submenu-popup {
    &.deployment-menu {
      .ant-menu-item-group {
        width: 50%;
        &:first-child {
          margin-bottom: 2rem;
        }
      }
      &.publishing-menu {
        .ant-menu-item-group {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .ant-menu-submenu-popup {
    .ant-menu {
      padding: 1rem;
      .ant-menu-item {
        &.support-center {
          a {
            font-size: inherit;
            color: inherit;
          }
        }
      }
    }
    &.deployment-menu {
      .ant-menu-item-group {
        padding-right: 0;
        width: 100%;
        margin-bottom: 1rem;
        &:first-child {
          margin-bottom: 1rem;
        }
        &:last-child {
          padding-right: 0;
          margin-bottom: 0;
        }
      }
      &.publishing-menu {
        .ant-menu-item-group {
          &:last-child {
            padding-right: 1.5rem;
          }
        }
      }
    }
    &.header-menu {
      .ant-menu {
        &::after {
          display: none;
        }
      }
    }
  }
  .header-dropdown {
    &.ant-dropdown-menu {
      &.user-dropdown {
        padding: 0.75rem 0;
      }
      &::before {
        display: none;
      }
    }
  }
}

.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-horizontal > .ant-menu-submenu:hover::after,
.ant-menu-horizontal > .ant-menu-item-active::after,
.ant-menu-horizontal > .ant-menu-submenu-active::after,
.ant-menu-horizontal > .ant-menu-item-open::after,
.ant-menu-horizontal > .ant-menu-submenu-open::after,
.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-horizontal > .ant-menu-submenu-selected::after {
  display: none;
}
// .ant-menu-item .ant-menu-item-icon + span,
// .ant-menu-submenu-title .ant-menu-item-icon + span,
// .ant-menu-item .anticon + span,
// .ant-menu-submenu-title .anticon + span {
//   margin-left: 0px;
// }
.react-tel-input .form-control:focus {
  border-color: #8a77ff;
  outline: 0;
  box-shadow: none;
}
.react-tel-input .form-control {
  color: #67748b;
  background-color: #fafbff;
  border: 1px solid #e9edf4;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open a,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open a:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #1ac884 !important;
}
