@import url("https://fonts.googleapis.com/css?family=Cabin:400,700&display=swap");

html {
  font-size: 16px;
  height: 100%;
}

body {
  font-family: Cabin !important;
  font-size: 0.875rem;
  color: $default;
  height: 100%;
  line-height: 1.188rem;
  letter-spacing: 0.5px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.btn {
  font-weight: 700;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.line-height-14 {
  line-height: 0.875rem;
}

.line-height-16 {
  line-height: 1rem;
}

.line-height-22 {
  line-height: 1.375rem;
}

.line-height-26 {
  line-height: 1.625rem;
}

.height-36 {
  height: 2.25rem !important;
}

.height-40 {
  height: 2.5rem !important;
}

.height-48 {
  height: 3rem !important;
}

.height-54 {
  height: 3.375rem !important;
}

.height-60 {
  height: 3.75rem !important;
}

.height-100 {
  height: 6.25rem !important;
}

.height-120 {
  height: 7.5rem !important;
}

.height-320 {
  height: 20rem !important;
}

.min-width-59 {
  min-width: 3.688rem !important;
}

.width-80 {
  width: 5rem !important;
}

.width-120 {
  width: 7.5rem !important;
}

.width-148 {
  width: 9.25rem !important;
}

.width-160 {
  width: 10rem !important;
}

.width-176 {
  width: 11rem !important;
}

.width-180 {
  width: 11.25rem !important;
}

.width-200 {
  width: 12.5rem !important;
}

.width-220 {
  width: 13.75rem !important;
}

.width-240 {
  width: 15rem !important;
}

.width-260 {
  width: 16.25rem !important;
}

.width-300 {
  width: 18.75rem !important;
}

.width-320 {
  width: 20rem !important;
}

.width-330 {
  width: 20.63rem !important;
}

.font-size-12 {
  font-size: 0.75rem !important;
}

.font-size-14 {
  font-size: 0.875rem !important;
}

.font-size-15 {
  font-size: 0.9375rem !important;
}

.font-size-18 {
  font-size: 1.125rem !important;
}

.text-muted {
  font-size: 0.8125rem;
}

.z-overly-10 {
  z-index: 10;
}

.page-title {
  font-size: 2.25rem;
  color: $primary;
}

.wrapper {
  padding: 4rem 2rem 2rem;
  .btn-default {
    padding: 0.7rem 2rem;
    min-width: 18.75rem;
  }
}

.btn-danger-light {
  color: $red;
  background-color: rgba($red, 0.1);
  &:hover {
    color: $white;
    background-color: $red;
  }
}

.btn-medium {
  width: 111px;
  padding: 0px 0px 0px 32px;
}
.btn-small {
  font-size: 0.875rem;
  padding: 0.413rem 1.272rem;
}

.btn-default {
  padding: 0.9rem 2rem;
}

.btn-white {
  background: $white;
  box-shadow: -2px 8px 16px rgba($purple-light, 0.04);
}

.btn-secondary-light {
  color: $secondary;
  background-color: $btn-secondary-light-bg;
  border-color: $btn-secondary-light-bg;
}

.danger-light {
  color: $danger-light;
}

.bg-danger-light {
  background: rgba($danger-light, 0.1);
}

.badge-secondary-light {
  color: $green;
  background-color: rgba($green, 0.1);
  font-weight: 700;
  font-size: 1rem;
}

body {
  &::after {
    position: fixed;
    top: 0;
    z-index: -1;
    background: rgba($primary, 0.1);
    content: "";
    left: 0;
    right: 0;
    bottom: 0;

    transition: all 0.5s ease 0s;
    opacity: 0;
  }
  &.menu-overlay {
    &::after {
      opacity: 1;
      z-index: 9;
    }
  }
}

.border-width-4 {
  border-width: 4px !important;
}

.text-default {
  color: $default !important;
}

.ant-tooltip {
  font-size: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-tooltip-inner {
  padding: 0.625rem 1.063rem;
}

.bg-gray {
  background: $input-number-bg !important;
}

.text-dark-gray {
  color: $tabs-color;
}

.ant-statistic {
  display: inline-block;
  .ant-statistic-content {
    .ant-statistic-content-value {
      font-size: 0.875rem;
      .ant-statistic-content-value-decimal {
        font-size: inherit;
      }
    }
    .ant-statistic-content-suffix {
      font-size: 0.875rem;
    }
    .ant-statistic-content-prefix {
      .svg-inline--fa {
        font-size: 0.875rem;
      }
    }
  }
}

.graph-text {
  color: $graph-text;
}

.icon-gray {
  color: $input-placeholder-color;
}

.border-light-gray {
  border-color: $hr-border-color;
}

.g2-tooltip {
  background-color: $secondary !important;
}

.main-tooltip-value {
  .info-date {
    font-size: 0.75rem;
  }
  .info-value {
    font-size: 1rem;
    font-weight: 700;
  }
}

.ajax-global-spin {
  .ant-spin {
    height: 100vh !important;
    max-height: unset !important;
  }
}

.ajax-global-spin {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
}

.ant-select-selection__rendered {
  .ant-select-selection__choice {
    background: rgba($input-focus-border-color, 0.1);
    color: $input-focus-border-color;
    font-size: 1rem;
    .ant-select-selection__choice__remove {
      color: $input-placeholder-color;
    }
  }
}

.user-avtar-img {
  width: 50px;
}

// Responsive media css
@media (max-width: 767px) {
  .ant-drawer-bottom.ant-drawer-open {
    &.channel-drawer {
      .ant-drawer-content-wrapper {
        height: 167px !important;
      }
    }
  }
}

.rounded-circle-hover {
  border-radius: 100%;
  width: 32px;
  height: 32px;
  border-color: transparent !important;
  &:hover {
    background: $card-bg-light;
  }
  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }
}

// Syncfusion TimeScale
.e-schedule .e-vertical-view .e-current-timeline {
  border-top: 2.5px solid $secondary;
  height: 0;
}

.e-schedule .e-vertical-view .e-previous-timeline {
  border-top: 2.5px solid $secondary;
}

.status-success {
  background: $btn-secondary-light-bg !important;
  .tag-success {
    color: $secondary;
    font-size: 1rem;
    height: 1.25rem !important;
  }
}

.status-failur {
  background: #ffebea !important;
  .tag-failur {
    color: #ff3b36;
    font-size: 1rem;
    height: 1.25rem !important;
  }
}

.status-disabled {
  background: #fbd5d5 !important;
  .tag-disabled {
    color: #ff0000 !important;
    font-size: 1rem;
    height: 1.25rem !important;
  }
}

.status-expired {
  background: #fffbc9 !important;
  .tag-expired {
    color: #e1c227;
    font-size: 1rem;
    height: 1.25rem !important;
  }
}

.status-trial {
  background: #ffebea !important;
  .tag-trial {
    color: #ff7b00;
    font-size: 1rem;
    height: 1.25rem !important;
  }
}
.status-active {
  background: $btn-secondary-light-bg !important;
  .tag-active {
    color: $secondary;
    font-size: 1rem;
    height: 1.25rem !important;
  }
}

.status-pending {
  background: #eef8ff !important;
  .tag-pending {
    color: #128dff;
    font-size: 1rem;
    height: 1.25rem !important;
  }
}

.action-icon {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.fa-trash-icon:hover {
  color: red;
}

.left-border {
  border-left-style: solid;
  border-left-color: $secondary;
}

// single graph main content
.graph-content {
  .text-subtitle {
    font-size: 1.125rem;
    color: $primary;
  }
  .graph-buttons {
    .btn {
      color: $graph-text;
      font-size: 0.875rem;
      padding: 0 0.5625rem;
      border-radius: 1.875rem;
      &:hover,
      &:focus {
        color: $secondary;
        background-color: $btn-secondary-light-bg;
        box-shadow: none;
      }
    }
    .selected {
      color: $secondary;
      background-color: $btn-secondary-light-bg;
      box-shadow: none;
    }
  }
}

// Tooltip arrow at bottom-center
.g2-tooltip:after {
  z-index: -1;
  position: absolute;
  top: 98.1%;
  left: 70%;
  margin-left: -25%;
  content: "";
  width: 0;
  height: 0;
  border-top: solid 5px $secondary;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

//User profile picture upload
.user_profile_image {
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 100%;
  }
  .pic_added {
    .ant-upload.ant-upload-select-picture-card {
      border-radius: 100%;
    }
    img {
      border-radius: 100%;
    }
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0;
  }
}

// Publishing menu
.publishing-menu {
  .ant-checkbox-group {
    line-height: 1.3;
  }
  .ant-checkbox + span {
    padding-right: 4px;
    padding-left: 4px;
    font-size: 0.6875rem;
  }
  .ant-checkbox-group-item {
    margin-right: 2px;
  }
  .ant-checkbox-inner {
    width: 12px;
    height: 12px;
  }
  .ant-checkbox-inner::after {
    left: 7%;
  }
  .ant-menu-sub.ant-menu-vertical {
    height: 450px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
